<template>
  <div class="credit-history-main">
    <div :class="isDarkSkin ? 'card-dark-tabs' : 'card-light-tabs'">
      <div class="px-5 mx-auto d-flex justify-content-between">
        <b-button
          v-for="tab in tabsModule"
          :key="tab.name"
          style="height: 45px"
          :class="
            activeRoute(tab.route)
              ? 'button-active buttons-menu'
              : isDarkSkin
              ? 'button-inactive-dark buttons-menu'
              : 'button-inactive-light buttons-menu'
          "
          @click="changeTab(tab)"
          size="md"
          class="col"
        >
          <span>{{ tab.name }}</span>
          <b-badge
            v-if="G_COUNT_ANALYSIS_CR.toRemove > 0 && tab.name == 'ITEMS'"
            pill
            variant="secondary"
            class="ml-1"
          >
            {{
              G_COUNT_ANALYSIS_CR.toRemove > 99
                ? "99+"
                : G_COUNT_ANALYSIS_CR.toRemove
            }}
          </b-badge>
          <b-badge
            v-if="tab.name === 'CREDIT REPORT' && cPendingNcrByClient > 0"
            pill
            variant="danger"
            class="ml-1"
          >
            {{ cPendingNcrByClient }}
          </b-badge>
          <b-badge
            v-if="tab.name === 'WORKPLAN' && G_COUNT_PENDING_WP_CLIENT > 0"
            pill
            variant="danger"
            class="ml-1"
          >
            {{ G_COUNT_PENDING_WP_CLIENT }}
          </b-badge>
          <b-badge
            v-if="tab.name === 'UPDATES' && updates > 0"
            pill
            variant="danger"
            class="ml-1"
          >
            {{ updates }}
          </b-badge>
          <b-badge
            v-if="tab.name === 'RECOMMENDATIONS' && G_COUNTER_ALL_SERVICES > 0"
            pill
            variant="danger"
            class="ml-1"
          >
            {{ G_COUNTER_ALL_SERVICES }}
          </b-badge>
          <b-badge
            v-if="tab.name === 'DISPUTES' && pendingDisputes > 0"
            pill
            variant="danger"
            class="ml-1"
            v-b-tooltip.hover.top="'Missing files'"
          >
            <span v-if="missingFiles > 0">{{ missingFiles }}</span>
          </b-badge>
        </b-button>
      </div>
    </div>
    <main class="mt-2">
      <div :class="isDarkSkin ? 'card-dark-content' : 'card-light-content'">
        <div class="px-5 py-2">
          <router-view></router-view>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      updates: "ReportStore/G_UPDATES_DISPUTE",
      missingFiles: "ReportStore/G_MISSING_FILES",
      client: "DebtSolutionClients/G_CLIENTS",
      G_COUNT_ANALYSIS_CR: "AnalysisCrStore/G_COUNT_ANALYSIS_CR",
      G_COUNTER_ALL_SERVICES: "clients-store/G_COUNTER_ALL_SERVICES",
      cPendingNcrByClient: "RequestNcrStore/G_PENDING_BY_CLIENT",
      G_COUNT_PENDING_WP_CLIENT: "clients-store/G_COUNT_PENDING_WP_CLIENT",
      countId: "ReportStore/G_COUNT_ID",
      countUb: "ReportStore/G_COUNT_UB",
      pendingDisputes: "ReportStore/G_PENDING_DISPUTES",
    }),
    tabsModule() {
      const slug = {
        4: "adm", //administration
        5: "ds", //debt solution
        6: "ce", //credit experts
        7: "bc", //boost credit
        16: "man", // management
        18: "qua", //quality
        20: "cn", //connection
        22: "cs", //customer service
        23: "fn", //financial
        28: "spad", //specialist analyst department
        29: "spfa", //specialist financial approval
      };
      let slugModule = `${slug[this.moduleId]}${
        this.isGeneral ? "-general" : ""
      }`;
      let routes = [
        { name: "CREDIT REPORT", route: `report-detail-${slugModule}` },
        { name: "WORKPLAN", route: `workplan-detail-${slugModule}` },
        { name: "DISPUTES", route: `dispute-detail-${slugModule}` },
        { name: "UPDATES", route: `updates-detail-${slugModule}` },
        {
          name: "RECOMMENDATIONS",
          route: `recommendation-detail-${slugModule}`,
        },
      ];

      if ([5, 6, 7, 18, 20, 22, 23, 28, 29].includes(this.moduleId)) {
        routes.unshift({
          name: "ITEMS",
          route: `analysis-cr-${slugModule}`,
        });
      }
      // if (![4, 5, 6, 7, 11, 16, 18, 20, 22, 28, 29].includes(this.moduleId)) {
      //   routes = routes.filter((tab) => tab.name != "CREDIT REPORT");
      // }

      return routes;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isGeneral() {
      return this.$route.matched[0].meta.general;
    },
  },
  methods: {
    changeTab(tab) {
      if (this.$route.name === tab.route) return;
      this.$router.push({ name: tab.route });
    },
    activeRoute(route) {
      return this.$route.name === route;
    },
    async getUpdates() {
      const { data } = await ClientsOptionsServices.getCountUpdates({
        accountId: this.$route.params.idClient,
      });
      this.$store.dispatch("ReportStore/A_UPDATES_DISPUTE", data);
    },
    async getMissingFiles() {
      const { data } = await ClientsOptionsServices.getMissingFiles({
        accountId: this.$route.params.idClient,
      });
      this.client.missing_files = data.data[0].missing_files;
      await this.$store.dispatch("ReportStore/A_MISSING_FILES", data);
    },
    async getCountAccountsToRemove() {
      const params = {
        ncrRequestId: 0,
        idClient: this.$route.params.idClient,
        typeView: "REMOVE",
        leadId: 0,
        toViewRates: false,
      };
      await this.$store.dispatch("AnalysisCrStore/A_COUNT_ANALYSIS_CR", params);
    },
  },
  async mounted() {
    this.getUpdates();
    this.getMissingFiles();
    await this.getCountAccountsToRemove();
  },
};
</script>

<style scoped>
* {
  --light-bg-color: #f1f1f1;
  --dark-bg-color: #232328;
  --primary-color: #3f7afa;
}
.card-dark-tabs {
  padding: 20px 0px;
  background-color: var(--dark-bg-color);
  border-radius: 10px;
}
.card-light-tabs {
  padding: 20px 0px;
  background-color: var(--light-bg-color);
  border-radius: 10px;
}
.card-light-content {
  background-color: var(--light-bg-color);
  border-radius: 10px;
}
.card-dark-content {
  background-color: var(--dark-bg-color);
  border-radius: 10px;
}
.buttons-menu {
  margin: 0 15px;
  width: 200px;
  border: 0px !important;
}
.counter-style {
  width: 16px;
  height: 16px;
  background-color: red;
  padding: 3px;
  border-radius: 50%;
}
.button-active {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.button-inactive-light {
  background-color: white !important;
  color: #606060 !important;
}
.button-inactive-dark {
  background-color: #243250 !important;
  color: var(--primary-color) !important;
}
</style>
